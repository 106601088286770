
.spot {
  height:1.5em;
  width:1.5em;
  overflow:hidden;
  margin:0.1em;
  border-radius:1em;
  background-color:#218fff;
  border:2px solid #1d1e20;
  transition:all 0.3s ease;
}

.out {
  background-color:#ffac00;
  opacity:0.75;
}

.processingcontainer {
  perspective:512px;
  position:relative;
  height:6em;
}

.layer {
  display: grid;
  position:absolute;
  margin-left:-2em;
  grid-template-columns: repeat(3, 1fr);
  left:50%;
}

.layer:first-child {
  transform:translate3D(0, 0, 0);
}
.layer {
  transform:translate3D(-0.8em, 0.8em, 0.8em);
}
.layer:last-child {
  transform:translate3D(-1.6em, 1.6em, 1.6em);
}
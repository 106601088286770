
:root {
  --title-bar-bg: #7e7e7e;
  --panel-bg: #e9e9e9cc;
  --title-color: rgb(255, 255, 255);
  --text-color: #282c34;
  --green-text: #00661a;
  --highlight-color: #ffb700;
  --black:var(--text-color);
}

.title-panel {
  background-color:var(--title-bar-bg);
  color:var(--title-color);
  margin:2px;
  padding:10px;
}

.content-panel {
  background-color:var(--panel-bg);
  color:var(--text-color);
  margin:2px;
  padding:10px;
}

.content-panel > .title-panel {
  margin:0;
}
.title-panel > .content-panel {
  margin:0;
}

h1, h2, h3, h4, p {
  color:inherit!important;
  padding:0;
}

html {
  background-color:white;
  color:var(--text-color);
  background: url(/background-wide02.jpg) center center;
  background-attachment:fixed;
  height: 100%;
  background-size: cover;
  font-weight:300;
}

html.transparentBg {
  background-color:transparent;
  background: none;
}

body {
  margin: 0;
}

code {
  font-family: 'Overpass Mono', monospace;
}

.setuploader:last-child {
  border-bottom:none!important;
}

button {
  height: 38px;
  background-color: #ffac00;
  border-radius: 4px;
  box-shadow: 0 0 20px rgb(255, 89, 0) inset;
  transition: 0.2s;
  cursor: default;
  border-width:0;
  text-transform:uppercase;
  color:white;
  padding:3px 12px;
}

button[disabled] {
  filter:grayscale(100%);
  cursor:not-allowed;
  opacity:0.5;
}

button:active {
  transform:scale(0.96);
}

h1, h2, h3, p {
  color:white;
}

a {
  color: #218fff;
}

#main h1,
#main h2,
#main h3,
#main p {
  margin-top:0;
}

.id {
  font-size:90%;
  opacity:0.5;
  margin-bottom:0.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  width: 100%;
  white-space: nowrap;
}
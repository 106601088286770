.box__dragndrop {
  display: none;
  
}

.box.has-advanced-upload {
  background-color: rgba(0, 0, 0, 0.5);
  transition: all 0.3s ease;
  color:white;
}

.box.has-advanced-upload .box__dragndrop {
  display: inline;
}

.box.is-dragover {
  background-color: rgba(0, 0, 0, 0.75);;
  outline-offset: -20px;
  color:black;
}

.box.is-dragover .box__icon {
  fill: #5ea8e8;
}

.box {
  font-size: 18px;
  background-color: #c8dadf;
  position: relative;
  max-width: 500px;
  width: 40vh;
  overflow:hidden;
  margin: 10px auto;
  display: flex;
  flex-direction:column;
  align-items:center;
  justify-content: center;
}

.box p {
  font-size:inherit;
}

.box__input {
  flex: 1;
  position: absolute;
  box-sizing:border-box;
  padding:20px;
}

.box__icon {
  width: 100%;
  height: 80px;
  fill: white;;
  display: block;
  margin-bottom: 40px;
}

.box__file {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.box__button {
  display:none;
}

.box:after {
  content: "";
  display: block;
  padding-bottom: 100%;
}

.dropzone {
  flex:1;
  display: flex;
  justify-content: center;
  align-items: center;
}
.App {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  font-size: 14px;
  padding:35px 65px;
  box-sizing: border-box;
}

.hideHeader .App-header,
.hideHeader .devices  {
  display:none;
}

.hideHeader .App {
  padding:15px 15px;
}

.App.framed {
  min-height:0;
}

.App-body {
  flex:1;
  display:flex;
  flex-direction:column;
  font-size: calc(10px + 1vmin)
}

.App-body > div:first-child {
  flex:1;
  flex-direction:column;
  display: flex;
  justify-content: center;
  align-content: center;
}

.App-logo {
  height: 40vmin;
}

.App-header {
  padding:0;
  display:flex;
  align-items:center;
  justify-content: left;
  padding:15px;
  background-color:var(--title-bar-bg);
}

.inframe .App-header {
  padding-top:15vh;
}

.framed {
  padding:0;
}

.framed .instructions p {
  padding-left:0;
  padding-right:0;
}

.instructions p {
  font-size:14px;
  font-weight:300;
  padding: 10px;
}
.App-header h1 {
}

.App-footer {
  padding:3vh 0;
}

.appstore-links {
  display:flex;
  flex-direction: row;
  align-items:center;
  justify-content: center;
  margin-bottom:2vh;
}

.appstore-links > a {
  height:50px;
}
.appstore-links img {
  max-height:100%;
  width:auto;
}

.linkedset .appstore-links > a {
  height:60px;
}

.App-header, .App-footer {
  color: white;
  /*background-color: #282c34;*/
}

.App-link {
  color: #09d3ac;
}

.overlay {
  position:fixed;
  left:0;
  top:0;
  height:100%;
  width:100%;
  background-color:rgba(255, 255, 255, 0);
  pointer-events:none;
  text-align:center;
  transition:all 0.2s ease;
  opacity:0;
  color:var(--black);
  box-sizing:border-box;
  padding:5em;
}

.overlay.shown {
  background-color:white;
  pointer-events:all;
  opacity:1;
}

.overlay.shown .inner {
  display:block;
  pointer-events:all;
  transform:translateY(0%);
}

.overlay .inner {
  transform:translateY(20%);
  transition:all 0.3s ease;
  pointer-events:none;
}

.overlay .inner {
  padding:2em 4em;
  margin:auto;
  font-size:65%;
  width:auto;
  margin:auto;
}

.account, .devicelinkbutton, .screentopright {
  position:absolute;
  top:2em;
  right:2em;
  outline:none;
}

.devicelinkbutton {
  background-color:#333;
  box-shadow:none;
}

.linkedset .devicelinkbutton {
  position:static;
  display: inline-block;
  margin:auto;
}

.devicelink input::selection {
  background-color:#000a;
}

.devicelink input {
  display: inline-block;
  width: 1em;
  text-align: center;
  margin: 0.2em;
  font-size: 26px;
  border-width: 1px 1px 1px 1px;
  border-radius: 4px;
  padding: 0.25em 0.35em;
}
.devicelink input:focus {
  border-color:var(--highlight-color);;
  color:var(--highlight-color);
}
.devicelink p, .qrwindow p {
  margin-bottom:1em;
}
.devicelink .inputs {
  margin:1em;
}
.devicelink .inputs input:nth-child(3) {
  margin-right:0.6em;
}

.cloudicon circle {
  stroke:black;
}
.cloudicon path {
  fill:var(--black);
}

.loader {
  border: 4px solid rgba(255, 255, 255, 0.1); /* Blue */
  border-top: 4px solid #f3f3f3; /* Light grey */
  border-radius: 50%;
  width: 30px;
  height: 30px;
  animation: spin 1s linear infinite;
  margin:auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

@keyframes pulse {
  0% { filter: brightness(0.9) }
  100% { filter: brightness(1); }
}

.devicelinkbutton.linked {
  animation: pulse 1s ease infinite alternate;
}

.link-success {
  line-height:1.5em;
  font-size:120%;
  padding:0.5em 1em 0.15em 1em;
  border:1px solid #aaa;
  background-color:#333;
  border-radius:5px;
  box-shadow:1px 1px 5px inset rgba(0, 0, 0, 0.76);
  margin-bottom:1em;
}

.link-success img {
  display:inline-block;
  vertical-align:text-top;
  height:1.35em;
  margin-right:0.25em;
}

input {
  border:1px solid #cccc;
  border-width: 0 0 1px 0;
  font-size:110%;
  color:var(--black);
  padding:0.2em 0;
  margin:0.1em;
  background-color:rgba(0, 0, 0, 0)!important;
  outline:none;
  display: block;
  width:100%;
}
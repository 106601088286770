

.devices {
  display:grid;
  grid-template-columns:repeat(auto-fill, minmax(240px, 1fr));
  grid-gap:5px;
  align-items:center;
  font-weight:500;
  justify-content:flex-start;
  user-select:none;
}

.panelnav {
  min-width:200px;
  user-select:none;
}

.panelnav ul {
  list-style-type:none;
  padding:0;
  margin:0;
}

.device .header {
  width:100%;
  display: flex;
  flex-direction:row;
  justify-content: space-between;
}

.device .label {
  font-size:120%;
  display: block;
}

.device .type {
  font-size:80%;
}
.device .type::before {
  content:"(";
}
.device .type::after {
  content:")";
}

.device .state {
  font-size:70%;
  font-weight:bold;
  text-transform:uppercase;
  color:#ccc;
}

.device .state-online {
  color:var(--green-text);
}
.device .state-offline {
  color:#666;
}

.licenses {

}

.device-dashboard {
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items:left;
  margin-top:2em;
}

.device-dashboard > .content-panel {
  flex:1;
}

.devices .device {
  display:flex;
  align-items:flex-start;
  flex-direction:column;
  line-height:1.5em;
  flex-basis:250px;
  opacity:0.65;
}

.device-dashboard {
  position:relative;
}

.device-dashboard.disabled > * {
  visibility:hidden;
  pointer-events:none;
}

.device-dashboard.disabled::after {
  content: "Device is offline.";
  position:absolute;
  top:0;
  bottom:0;
  left:0;
  right:0;
  display:flex;
  align-items:center;
  justify-content: center;
  font-size:200%;
  background-color:#ffffff33;
}

.devices .device.device-online {
  opacity:1;
}

.devices .device.selected {
  background-color:var(--highlight-color);
  font-weight:700;
  color:var(--black);
}

.devices .device .controls {
  margin-left:1em;
}

.devices .device .controls > * {
  cursor:pointer;
}

.devices .device .controls .remove {
  overflow:hidden;
  display:block;
  width:1em;
  height:1em;
  text-indent:9999px;
  position: relative;
}

.devices .device .controls .remove::before {
  content: "+";
  position: absolute;
  top:-0.25em;
  left:0;
  transform:rotateZ(45deg);
  text-indent: 0;
  font-size:2em;
  line-height:1em;
}

.data-preview-image {
    aspect-ratio:1/1;
    width:auto;
    background-size: contain;
    background-color:white;
    grid-area:pic;
}

.dataset h1, .dataset h2, .dataset h3 {
    margin:0;
}

.saving {
    pointer-events: none;
    filter:saturate(0.8);
    opacity:0.9;
}

.actions {
    grid-area:actions;
}

.dataset button {
    height:auto;
    margin:0 5px;
    padding:6px 12px;
    margin-top:-1px;
}

.dataset h3 {
    grid-area:title;
    font-size:120%;
    margin-top:10px;
}

.input {
    grid-area: input;
    display: flex;
    flex-direction: row;
    justify-content: left;
    align-items:center;
}

.input label {
    text-transform:uppercase;
    font-weight: bold;
    margin-right:15px;
}

.delete {
    grid-area: delete;
    align-items:center;
    justify-content: center;
    text-align:right;
    margin:15px;
}

.dataset {
    user-select:none;
    background-color:#e3e3e3;
    color:#383838;
    align-items:start;
    padding:0;
    display:grid;
    grid-column-gap: 15px;
    grid-template-columns: 128px auto;
    grid-template-rows: auto auto;
    grid-template-areas: "pic title delete"
                         "pic input delete"
}

.dataset:hover {
    background-color:#d1d1d1;
}

.dataset input[type=text] {
    background-color:#fefefe!important;
    margin:0;
    padding:5px;
}
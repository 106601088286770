
.progress {
  display:flex;
  flex-direction:row;
  color:#ccc;
  font-family: 'Overpass Mono', monospace;
  line-height:1.5em;
  width:100%;
}

.progresscontainer {
  flex:1;
  position:relative;
  background-color:#1e2f3e;
  border:1px solid #ccc;
  box-shadow:inset 1px 1px 5px rgba(0, 0, 0, 0.65);
  border-radius:10px;
  border:1px solid #1e2f3e;
  width:100%;
  overflow:hidden;
  height:2.5em;
  font-size:75%;
  text-align:center;
  margin-right:1em;
}

.progressbar {
  background-color:#5ea8e8;
  height:100%;
  transition:all 1s linear;
  padding: 0 10px;
  margin-left: -10px;
}

.progresstext {
  position:absolute;
  width:100%;
  left:50%;
  top:50%;
  transform:translateX(-50%) translateY(-50%);
  color:#ccc;
  font-family: 'Overpass Mono', monospace;
  line-height:1.1em;
  mix-blend-mode:color-dodge;
}

.upload {
  display:grid;
  grid-template-columns:2fr 1fr 1fr 4fr;
  margin: auto;
  justify-items: center;
  align-items: center;
  overflow: hidden;
  font-size:90%;
}

.upload .processingcontainer {
  transform: scale(0.65) translateY(-1.5em);
  height:5em;
}

.upload h3 {
  margin-bottom:0.2em;
}

.upload p {
  margin-bottom:0.5em;
  overflow: hidden;
  text-overflow: ellipsis;
  word-break: break-all;
  width: 100%;
  white-space: nowrap;
}

.link {
  font-size:90%;
  word-break:break-all;
}